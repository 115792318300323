import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { RegularCardBox } from 'src/legacy/components/Cards/RegularCardBox';

const LEGACY_ONBOARDING_FORM_WIDTH = 460;
const useStyles = makeStyles((theme: Theme) => ({
  onboardingCard: {
    padding: theme.spacing(3, 3.5, 3.5, 5),
    width: LEGACY_ONBOARDING_FORM_WIDTH,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));
/**
 * @deprecated [GoogleLoginForInternalUser] will be removed after releasing google auth
 */
const OnboardingCard: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <RegularCardBox className={classes.onboardingCard}>
      {children}
    </RegularCardBox>
  );
};

export default OnboardingCard;
