import React, { useCallback, useContext } from 'react';
import { I18n } from 'aws-amplify';
import {
  makeStyles,
  Theme,
  CircularProgress,
  Box,
  MenuItem,
} from '@material-ui/core';
import { Formik, FormikErrors, FormikTouched, getIn } from 'formik';
import * as Yup from 'yup';
import debounce from 'lodash/debounce';
import {
  OnboardingFormProps,
  OnboardingFormValues,
} from 'src/legacy/components/Onboarding/OnboardingForms/onboardingFormTypes';
import * as Colors from 'src/theme/colors';
import { filterNonAlphanumericCharacters } from 'src/utils/StringUtils';
import DomainsClient from 'src/clients/DomainsClient';
import { PortalUrlValidation } from 'src/constants/errorConsts/errorCodesConsts';
import { OnboardingButtonContainer } from 'src/legacy/components/Onboarding/OnboardingForms/OnboardingButtonContainer';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { typography13RegularStyle } from 'src/legacy/components/Text';
import {
  BaseTextField,
  SharpOutlinedTextField,
} from 'src/legacy/components/TextField';
import {
  SuccessCheckmarkIcon,
  ErrorCloseMarkIcon,
} from 'src/legacy/components/Icons';
import * as Constants from 'src/constants';
import { TrackEvent } from 'src/utils/AnalyticsUtils';
import OnboardingButton from 'src/legacy/components/Onboarding/OnboardingButton';
import { OnboardingTrackEventName } from 'src/constants/onboardingConsts';
import { FlagsContext } from 'src/context';
import { FormInputsContainer } from 'src/legacy/components/Onboarding/OnboardingForms/OnboardingLoginForm';
import { OnboardingFormError } from 'src/legacy/components/Onboarding/OnboardingForms/OnboardingFormError';
import RowDivider from 'src/legacy/components/RowDivider';
import { ensureApiError } from 'src/utils/Errors';
import { reservedSubdomains } from 'src/constants/hostnameConsts';

const schema = Yup.object().shape({
  name: Yup.string()
    .matches(
      /^([a-zA-Z]{1,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{1,}\s?([a-zA-Z]{1,})?)/,
      'Please enter first and last name',
    )
    .required('Name is required'),
  portalUrl: Yup.string()
    .required(Constants.PORTAL_URL_REQUIRED_ERROR)
    .test(
      'portal-url-at-least-4-letters',
      Constants.PORTAL_URL_MUST_BE_MORE_THAN_4,
      (value) => value && value.length >= 4,
    )
    .test(
      'portal-url-at-most-30-letters',
      Constants.PORTAL_URL_MUST_BE_LESS_THAN_30,
      (value) => value && value.length <= 30,
    )
    .test(
      'portal-name-valid',
      Constants.PORTAL_NAME_INCLUDES_RESERVED_WORD,
      (value) => value && !reservedSubdomains.includes(value),
    ),
  foundBy: Yup.string().required('How you found us is required'),
  foundByCustom: Yup.string().when('foundBy', {
    is: 'other',
    then: Yup.string()
      .trim()
      .min(4, 'Must have at least 4 characters')
      .max(50, 'Must not exceed 50 characters')
      .required('How you found us is required'),
  }),
  companyName: Yup.string()
    .trim()
    .required('Company name is required')
    .min(1, 'Company name must be at least 1 character')
    .max(30, 'Company name must be less than 30 characters'),
});

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    color: Colors.BlackHeadings,
  },
  spinnerWrapper: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    width: '20px',
  },
  portalUrlLabelHelper: {
    color: Colors.GraySmall,
  },
  portalUrlInput: {
    width: 'calc(100% - 100px)',
    paddingRight: 0, // remove padding that is added from endAdornment
    '&&>input': {
      textAlign: 'right',
      ...typography13RegularStyle,
    },
    '&& .MuiInputBase-input': {
      paddingRight: theme.spacing(1),
    },
  },
  domainSuffix: {
    display: 'flex',
    alignItems: 'center',
    color: Colors.BlackHeadings,
    position: 'absolute',
    left: '100%',
  },
  portalUrlField: {
    '& .MuiFormHelperText-root': {
      width: 273,
    },
  },
}));

const getFoundByOptions = () => {
  const foundByOptions = [...Constants.FOUND_PORTAL_SOURCES];
  for (let i = foundByOptions.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [foundByOptions[i], foundByOptions[j]] = [
      foundByOptions[j],
      foundByOptions[i],
    ];
  }

  return [
    ...foundByOptions,
    {
      id: 100,
      label: 'Other',
      value: 'other',
    },
  ];
};

/**
 * This function is used to get the error text to display
 * @param errorsState formik errors state
 * @param tochedState formik errors state
 * @returns
 */
export function getFormErrorText<T = OnboardingFormValues>(
  errorsState: FormikErrors<T>,
  touchedState: FormikTouched<T>,
) {
  const errorFieldKeys = Object.keys(errorsState);
  if (!errorFieldKeys.length) return '';

  return errorFieldKeys
    .reduce<string[]>((errorMessages, key) => {
      if (getIn(touchedState, key) && getIn(errorsState, key)) {
        const message: string = getIn(errorsState, key);
        errorMessages.push(message.replace('.', ''));
      }
      return errorMessages;
    }, [])
    .join(', ');
}

export const CreatePortalForm: React.FC<OnboardingFormProps> = ({
  initialValues,
  handleSubmitDone,
  trackedEvents,
  onAnalyticsEventTracked,
}) => {
  const classes = useStyles();
  const [portalUrlError, setPortalUrlError] = React.useState('');
  const [isValidating, setIsValidating] = React.useState(false);
  const [otherIsSelected, setOtherIsSelected] = React.useState(false);
  const foundPortalByOptions = getFoundByOptions();
  const { GoogleLoginForInternalUser } = useContext(FlagsContext);
  const TextFieldComponent = GoogleLoginForInternalUser
    ? SharpOutlinedTextField
    : BaseTextField;

  const OnboardingBtnContainerComponent = GoogleLoginForInternalUser
    ? React.Fragment
    : OnboardingButtonContainer;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values) => {
        const valuesObj = { ...values };
        if (otherIsSelected) {
          valuesObj.foundBy = values.foundByCustom;
        }
        if (!portalUrlError) {
          const isSuccess = await handleSubmitDone(valuesObj);
          if (
            isSuccess &&
            !trackedEvents[OnboardingTrackEventName.CompleteRegistration]
          ) {
            TrackEvent('Onboarding - Portal Workspace Created', {
              name: values.name,
              source: values.foundBy,
              source_other: values.foundByCustom,
              phone: values.phone,
              company_name: values.companyName,
              portal_URL: values.portalUrl,
            });

            // update the tracked events
            onAnalyticsEventTracked(
              OnboardingTrackEventName.CompleteRegistration,
            );
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        isValid,
        dirty,
        setFieldTouched,
      }) => {
        const validatePortalUrl = async (portalUrl: string) => {
          if (portalUrl.length < 4) {
            setPortalUrlError(Constants.PORTAL_URL_MUST_BE_MORE_THAN_4);
            setIsValidating(false);
            return;
          }
          if (portalUrl.length > 30) {
            setPortalUrlError(Constants.PORTAL_URL_MUST_BE_LESS_THAN_30);
            setIsValidating(false);
            return;
          }
          if (reservedSubdomains.includes(portalUrl)) {
            setPortalUrlError(Constants.PORTAL_NAME_INCLUDES_RESERVED_WORD);
            setIsValidating(false);
            return;
          }
          setIsValidating(true);
          try {
            await DomainsClient.ValidatePortalUrl(portalUrl);
            setPortalUrlError('');
            setIsValidating(false);
          } catch (err) {
            const error = ensureApiError(err);
            if (error.code === PortalUrlValidation.invalid) {
              setPortalUrlError('A portal with that name already exists.');
            } else {
              setPortalUrlError(
                'This Portal URL could not be used. Contact support for further assistance.',
              );
            }
            setIsValidating(false);
          }
        };

        const debounceAsyncFieldChange = useCallback(
          debounce((portalUrl: string) => validatePortalUrl(portalUrl), 1300),
          [],
        );

        const handleAsyncFieldChange = (
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          event.persist();
          if (event.target.name === 'companyName') {
            handleChange(event);
          }

          const formattedPortalUrl = filterNonAlphanumericCharacters(
            event.target.value,
          ).toLowerCase();

          setFieldValue('portalUrl', formattedPortalUrl);

          setIsValidating(false);
          setPortalUrlError('');
          if (formattedPortalUrl.length < 4) {
            setPortalUrlError(Constants.PORTAL_URL_MUST_BE_MORE_THAN_4);
          } else if (formattedPortalUrl.length > 30) {
            setPortalUrlError(Constants.PORTAL_URL_MUST_BE_LESS_THAN_30);
          } else if (reservedSubdomains.includes(formattedPortalUrl)) {
            setPortalUrlError(Constants.PORTAL_NAME_INCLUDES_RESERVED_WORD);
          } else {
            setIsValidating(true);
          }

          debounceAsyncFieldChange(formattedPortalUrl);
        };

        const showIcon =
          !isValidating &&
          values.portalUrl.length >= 4 &&
          values.portalUrl.length <= 30;

        // portalURL error should be shown if there is an error in the portalUrl field
        // or from the async event. It should also be shown if we touched the portalUrl field
        // or the companyName fields because companyName automaticatelly sets the value for portalUrl
        const portalUrlFieldError =
          ((touched.portalUrl || touched.companyName) && !!errors.portalUrl) ||
          !!portalUrlError;
        // disable the submit button
        // if user have not touched the form
        // or if there are errors and form is not valid
        // or if user have choosed other company option and not filled the custom value
        const isSubmitDisabled =
          !dirty || !isValid || (otherIsSelected && !values.foundByCustom);

        return (
          <form noValidate onSubmit={handleSubmit}>
            <FormInputsContainer>
              <TextFieldComponent
                sizeVariant="tall"
                InputProps={{
                  'data-testid': 'nameInput',
                }}
                id="name"
                autoFocus
                fullWidth
                type="text"
                name="name"
                variant="outlined"
                onBlur={handleBlur}
                onInput={handleChange}
                value={values.name}
                label={I18n.get('Full name')}
                placeholder={I18n.get('Full name')}
                error={Boolean(touched.name && errors.name)}
                helperText={
                  (!GoogleLoginForInternalUser &&
                    touched.name &&
                    errors.name) ||
                  ' '
                }
              />
              <TextFieldComponent
                select
                variant="outlined"
                InputProps={{
                  'data-testid': 'foundbyInput',
                }}
                sizeVariant="tall"
                name="foundBy"
                error={Boolean(touched.foundBy && errors.foundBy)}
                helperText={
                  !GoogleLoginForInternalUser &&
                  touched &&
                  errors &&
                  touched.foundBy &&
                  errors.foundBy
                    ? errors.foundBy
                    : ' '
                }
                InputLabelProps={{ shrink: false }}
                label="How did you find us?"
                value={values.foundBy}
                onChange={(e) => {
                  setOtherIsSelected(e.target.value === 'other');
                  handleChange(e);
                }}
                onBlur={handleBlur}
                fullWidth
              >
                {foundPortalByOptions.map((portalSourceOption) => (
                  <MenuItem
                    value={portalSourceOption.value}
                    key={portalSourceOption.id}
                    data-testid={`option-${portalSourceOption.value}`}
                  >
                    {portalSourceOption.label}
                  </MenuItem>
                ))}
              </TextFieldComponent>
              {otherIsSelected && (
                <TextFieldComponent
                  id="foundByCustom"
                  sizeVariant="tall"
                  InputProps={{
                    'data-testid': 'foundByCustomInput',
                  }}
                  fullWidth
                  type="text"
                  name="foundByCustom"
                  value={values.foundByCustom}
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Please specify"
                  placeholder=""
                  error={Boolean(touched.foundByCustom && errors.foundByCustom)}
                  helperText={
                    (!GoogleLoginForInternalUser &&
                      touched.foundByCustom &&
                      errors.foundByCustom) ||
                    ' '
                  }
                  autoFocus
                />
              )}
              <RowDivider
                mt={GoogleLoginForInternalUser ? 3 : 1}
                mb={GoogleLoginForInternalUser ? 0.5 : 3}
              />
              <TextFieldComponent
                id="companyName"
                fullWidth
                sizeVariant="tall"
                type="text"
                name="companyName"
                variant="outlined"
                value={values.companyName}
                onBlur={(e) => {
                  // set portal url touched once company name is touched
                  // so that we can show portal url error
                  if (GoogleLoginForInternalUser) {
                    setFieldTouched('portalUrl');
                  }
                  handleBlur(e);
                }}
                onInput={handleAsyncFieldChange}
                label={I18n.get('Company name')}
                placeholder={I18n.get('Company name')}
                error={Boolean(touched.companyName && errors.companyName)}
                helperText={
                  (!GoogleLoginForInternalUser &&
                    touched.companyName &&
                    errors.companyName) ||
                  ' '
                }
                InputProps={{
                  'data-testid': 'companyNameInput',
                }}
              />
              <Box position="relative">
                <TextFieldComponent
                  sizeVariant="tall"
                  variant="outlined"
                  id="portalUrl"
                  fullWidth
                  type="text"
                  name="portalUrl"
                  value={values.portalUrl}
                  onBlur={handleBlur}
                  onInput={handleAsyncFieldChange}
                  className={classes.portalUrlField}
                  error={Boolean(portalUrlFieldError)}
                  helperText={
                    // if portalUrl error exists show error when either of company name or portal url input is touched
                    (!GoogleLoginForInternalUser &&
                      (touched.portalUrl || touched.companyName
                        ? errors.portalUrl || portalUrlError
                        : '')) ||
                    ''
                  }
                  label={
                    <>
                      {I18n.get('Portal URL')}
                      {!GoogleLoginForInternalUser && (
                        <BaseTypography
                          className={classes.portalUrlLabelHelper}
                          component="span"
                        >
                          &nbsp;- You can connect a custom domain later
                        </BaseTypography>
                      )}
                    </>
                  }
                  InputProps={{
                    classes: {
                      root: classes.portalUrlInput,
                    },
                    'data-testid': 'portalUrlInput',
                    endAdornment: (
                      <div className={classes.domainSuffix}>
                        <BaseTypography color="inherit">
                          .copilot.app
                        </BaseTypography>
                        <div className={classes.spinnerWrapper}>
                          {isValidating && (
                            <CircularProgress
                              className={classes.spinner}
                              size={20}
                            />
                          )}
                          {showIcon &&
                            (!portalUrlError ? (
                              <SuccessCheckmarkIcon
                                fontSize="small"
                                data-testid="subdomain-verified"
                              />
                            ) : (
                              <ErrorCloseMarkIcon fontSize="small" />
                            ))}
                        </div>
                      </div>
                    ),
                  }}
                />
                {GoogleLoginForInternalUser && (
                  <OnboardingFormError
                    errorText={
                      getFormErrorText(errors, touched) || portalUrlError
                    }
                  />
                )}
              </Box>

              <OnboardingBtnContainerComponent>
                <OnboardingButton
                  type="submit"
                  data-testid="submit-button"
                  color="primary"
                  variant="contained"
                  fullWidth
                  htmlId="create-portal-submit-button"
                  disabled={
                    isSubmitDisabled || isValidating || Boolean(portalUrlError)
                  }
                >
                  Continue
                </OnboardingButton>
              </OnboardingBtnContainerComponent>
            </FormInputsContainer>
          </form>
        );
      }}
    </Formik>
  );
};
