import React, { useContext } from 'react';
import { Avatar, createStyles, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import { FlagsContext } from 'src/context';
import { CopilotAvatar } from 'src/legacy/components/UI/Avatars/CopilotAvatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3.75),
    },
    legacyLogo: {
      width: '100px',
      height: '100px',
      margin: 'auto',
    },
    noMargin: {
      margin: 0,
    },
  }),
);
export const OnboardingStepAvatar: React.FC = () => {
  const classes = useStyles();
  const { GoogleLoginForInternalUser } = useContext(FlagsContext);
  return (
    <div
      className={classNames(classes.root, {
        [classes.noMargin]: GoogleLoginForInternalUser,
      })}
    >
      {GoogleLoginForInternalUser ? (
        <CopilotAvatar />
      ) : (
        <Avatar
          alt="Copilot Technologies"
          src="/images/copilot-logo.svg"
          className={classes.legacyLogo}
        />
      )}
    </div>
  );
};
