import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { ErrorCallout } from 'src/legacy/components/Callout';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: 1,
      marginBottom: 20,
    },
  }),
);
/**
 * This component is used to wrap the error callout
 * with a fixed height to avoid the form jumping.
 * @returns {JSX.Element} Returns the error callout
 */
export const OnboardingFormError: React.FC<{
  errorText: string;
}> = ({ errorText }) => {
  const classNames = useStyles();
  return (
    <div className={classNames.root}>
      {errorText && <ErrorCallout>{errorText}</ErrorCallout>}
    </div>
  );
};
