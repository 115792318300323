import { Avatar, Theme, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

interface CopilotAvatarStyleProps {
  size?: number;
}

export const useStyles = makeStyles<Theme, CopilotAvatarStyleProps>(() =>
  createStyles({
    logo: {
      height: ({ size }) => size,
      width: ({ size }) => size,
      margin: 'auto',
    },
  }),
);

export const CopilotAvatar: React.FC<CopilotAvatarStyleProps> = ({
  size = 60,
}) => {
  const classes = useStyles({ size });

  return (
    <Avatar
      alt="Copilot"
      src="/images/copilot-logo.svg"
      className={classes.logo}
    />
  );
};
