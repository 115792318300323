import { API } from 'src/utils/AmplifyApiUtils';
import { UnAuthAPIName } from 'src/constants';
import { DomainForm } from 'src/store/domains/types';

export default class DomainsClient {
  /**
   * loadDomains loads domains from GET portal/domains api
   * @param void
   * @returns payload from the backend api
   * */
  static loadDomains() {
    return API.get('AppAPI', '/portal/domains', {
      queryStringParameters: { connectV2: true },
    });
  }

  /**
   * send domain settings data through POST portal/domains api
   * @param domainValues the data for setting up the domain
   * @returns payload from the backend api
   * */
  static addDomain(domainValues: DomainForm) {
    return API.post('AppAPI', '/portal/domains', {
      body: domainValues,
    });
  }

  /**
   * deleteDomain clears domain settings and stops stack update DELETE portal/domains api
   * @param void
   * @returns error from the backend api if any
   * */
  static deleteDomain() {
    return API.del('AppAPI', '/portal/domains', {});
  }

  static ValidatePortalUrl(companyName: string) {
    return API.get(
      UnAuthAPIName,
      `/portal/new/validate?companyName=${companyName}`,
      {},
    );
  }
}
