import { Box, BoxProps, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface StyleProps extends BoxProps {
  width: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 'auto',
    width: (props: StyleProps) => props.width,
    [theme.breakpoints.only('xs')]: {
      width: '100%!important',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

interface OnboardingFormLayoutProps extends BoxProps {
  width?: number;
}

const OnboardingFormLayout: React.FC<OnboardingFormLayoutProps> = ({
  width = 460,
  children,
}) => {
  const classes = useStyles({ width });

  return <Box className={classes.root}>{children}</Box>;
};

export default OnboardingFormLayout;
