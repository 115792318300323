import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { CopilotAdminAuthBackground } from 'src/theme/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '*': {
        margin: 0,
        padding: 0,
        boxSizing: 'inherit',
      },
      html: {
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale;',
      },
      body: {
        height: '100%',
        width: '100%',
        fontSize: '13px',
        lineHeight: 1.2,
        margin: 0,
        // only apply copilot bg color on desktop
        [theme.breakpoints.up('sm')]: {
          backgroundColor: CopilotAdminAuthBackground,
        },
      },
      '#app': {
        height: '100%',
        width: '100%',
      },
    },
    main: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      width: '100%',
      margin: 'auto',
    },
  }),
);

export const OnboardingPageLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
