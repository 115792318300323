import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import Button from 'src/legacy/components/Button';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import * as Colors from 'src/theme/colors';
import { GoogleIcon } from 'src/legacy/components/Icons';

export interface GoogleAuthButtonStyleProps {
  noBorderRadius?: boolean;
}

const useStyles = makeStyles<Theme, GoogleAuthButtonStyleProps>((theme) =>
  createStyles({
    root: {
      border: `1px solid ${Colors.NonHoverBorder}`,
      height: 48,
      borderRadius: ({ noBorderRadius }) =>
        noBorderRadius ? 0 : theme.shape.borderRadius,
    },
    icon: {
      height: 16,
      width: 16,
    },
  }),
);

export interface GoogleAuthButtonProps extends GoogleAuthButtonStyleProps {
  onClick?: () => void;
}

export const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
  onClick,
  noBorderRadius,
}) => {
  const classes = useStyles({ noBorderRadius });
  return (
    <Button
      fullWidth
      htmlId="googleSignIn"
      startIcon={<GoogleIcon className={classes.icon} />}
      onClick={onClick}
      className={clsx(classes.root)}
      size="large"
    >
      <BaseTypography fontType="15Medium">Continue with Google</BaseTypography>
    </Button>
  );
};
