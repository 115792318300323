import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    onboardingButtonContainer: {
      width: '100%',
      marginTop: theme.spacing(5.5),
    },
  }),
);

export const OnboardingButtonContainer: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.onboardingButtonContainer}>{children}</div>;
};
